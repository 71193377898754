<template>
  <div class="home position-relative">
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center">
        <h5></h5>
        <!-- <h5 class="text-white m-0 p-0" style="text-shadow: 0 0 4px rgba(0,0,0, .3);">Hello, {{ name }}</h5> -->
        <router-link v-if="token == '' && userId == ''" to="/login" class="text-white text-decoration-none d-flex align-items-center gap-2">
          Login
        </router-link>
        <div v-else-if="token != '' || userId != ''" class="d-flex gap-2">
          <span class="text-white text-decoration-none d-flex align-items-center gap-2">
            <div class="bg-secondary d-flex justify-content-center align-items-center rounded-circle bg-white" style="width: 35px;height: 35px;">
              <i class="bi bi-chat-left-dots text-secondary"></i>
            </div>
          </span>
          <router-link to="/profil" class="text-white text-decoration-none d-flex align-items-center gap-2">
            <div class="bg-secondary d-flex justify-content-center align-items-center rounded-circle bg-white" style="width: 35px;height: 35px;">
              <i class="bi bi-person-fill fs-3 text-secondary"></i>
            </div>
          </router-link>

        </div>
      </div>
      <!-- <h3 class="fw-light text-white mt-3">Online Loket.</h3> -->
    </div>
    <div class="p-3 rounded bg-white box-info shadow-lg overflow-hidden">
      <div class="row">
        <div class="col-2 d-flex flex-column justify-content-center" @click="navcard(1)">
          <h6 style="font-size: 11px;" class="text-nav-a1 text-center m-0 p-0 text-primary">Saldo</h6>
          <h5 class="text-nav-a2 fw-light text-center text-primary mt-1" style="font-size: 9px;"><i class="bi bi-circle-fill"></i></h5>
        </div>
        <div class="col-2 d-flex flex-column justify-content-center" @click="navcard(3)">
          <h6 style="font-size: 11px;" class="text-nav-c1 text-center m-0 p-0 text-secondary">Voucher</h6>
          <h5 class="text-nav-c2 fw-light text-center text-secondary mt-1" style="font-size: 9px;"><i class="bi bi-circle-fill"></i></h5>
        </div>
        <div class="col-2 d-flex flex-column justify-content-center" @click="navcard(5)">
          <h6 style="font-size: 11px;" class="text-nav-e1 text-center m-0 p-0 text-secondary">UV</h6>
          <h5 class="text-nav-e2 fw-light text-center text-secondary mt-1" style="font-size: 9px;"><i class="bi bi-circle-fill"></i></h5>
        </div>
        <div class="col-2 d-flex flex-column justify-content-center" @click="navcard(2)">
          <h6 style="font-size: 11px;" class="text-nav-b1 text-center m-0 p-0 text-secondary">TV</h6>
          <h5 class="text-nav-b2 fw-light text-center text-secondary mt-1" style="font-size: 9px;"><i class="bi bi-circle-fill"></i></h5>
        </div>
        <div class="col-2 d-flex flex-column justify-content-center" @click="navcard(4)">
          <h6 style="font-size: 11px;" class="text-nav-d1 text-center m-0 p-0 text-secondary">RPT</h6>
          <h5 class="text-nav-d2 fw-light text-center text-secondary mt-1" style="font-size: 9px;"><i class="bi bi-circle-fill"></i></h5>
        </div>
        <!-- <div class="col-4">
          <h6 style="font-size: 11px;" class="m-0 p-0">Voucher.</h6>
          <h5 class="fw-light" style="font-size: 13px;">{{ formatRupiah(voucher) }}</h5>
        </div>
        <div class="col-4">
          <h6 style="font-size: 11px;" class="m-0 p-0">Mine.</h6>
          <h5 class="fw-light" style="font-size: 13px;">{{ formatRupiah(rpt) }}</h5>
        </div>
        <div class="col-4">
          <h6 style="font-size: 11px;" class="m-0 p-0">RPT Coin.</h6>
          <h5 class="fw-light" style="font-size: 13px;">RpT. 0</h5>
        </div> -->
      </div>
      <hr class="m-0 mb-2" />
      <h6>{{ viewText }}</h6>
      <h1 class="fw-light">{{ formatRupiah(viewSaldo) }}</h1>
      <small class="d-block my-2 text-muted">Mulai transaksi dan cek transaksi anda.</small>
      <img src="@/assets/monas.png" style="position: absolute;z-index: 0;bottom: 0;right: 0;width: 100px;">
      <!-- <h6>Wallet.</h6>
      <div class="row">
        <div class="col">
          <small>RPT <i class="fa-solid fa-coins text-warning"></i></small>
          <h5>0</h5>
        </div>
        <div class="col">
          <small>Energi <i class="fa-solid fa-bolt text-primary"></i></small>
          <h5>0</h5>
        </div>
      </div> -->
      <!-- <div class="d-flex align-item-center text-success" v-if="rowStatus == 1" style="height: 40px">
        <i class="bi bi-circle-fill me-2"></i><span>Active</span>
      </div>
      <div class="d-flex align-item-center text-secondary" v-else style="height: 40px">
        <i class="bi bi-circle-fill me-2"></i><span>Inactive</span>
      </div> -->
      <button @click="checkLogin('/voucher')" class="btn btn-primary me-2"><i class="bi bi-plus-circle-dotted me-2"></i>Top up</button>
      <!-- <button class="btn btn-outline-primary me-2" @click="openFloat"><i class="bi bi-plus-circle-dotted me-2"></i>RPT Dashboard</button> -->
      <button @click="checkLogin(viewRiwayat)" class="btn btn-outline-primary"><i class="bi bi-clock-history me-2"></i>Riwayat</button>
    </div>
  </div>
  <div class="container p-4" style="margin-top: 120px;"> 
     <!--nanti taruh permainan disini  -->
      <div class="bg-ref" v-if="referral == null">
        <div class="bg-white box-ref">
          <form 
            @submit.prevent="saveReferralCode"
          >
            <div class="input-group my-3">
              <div class="row w-100">
                <div class="col-10">
                  <input
                    type="text"
                    v-model="referralCode" 
                    name="code"
                    class="form-control w-100"
                    placeholder="Code Referral"
                    required
                  />
                </div>
                <div class="col-2">
                  <button class="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <i class="fa-solid fa-circle-question fs-4"></i>
                  </button>
                  <!-- Modal -->
                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">Kode Referral</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          Kode Referral didapatkan dari agen yang menawarkan aplikasi atau hubungi WhatsApp +62 857-7008-5997 untuk mendapatkan kodenya.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <input type="checkbox" v-model="agreementChecked" class="me-2" required />
              <span>Setuju</span>
            </div>
            <button class="btn btn-primary w-100 mt-2" type="submit">
              Ya, Saya Mengerti
            </button>
          </form>
        </div>
      </div>     
      <br>
    <div class="mb-3 d-flex align-items-center">
      <small class="text-muted" style="width: 25%;">Main Menus</small>
      <div class="pt-1" style="width: 75%;"><hr></div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <router-link to="/minning" style="height: 73px;display: flex;justify-content: center;align-items: center;" class="btn w-custom rounded-circle text-white fw-bold fs-3 bg-icon-up"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M14.531 12.469L6.619 20.38a1 1 0 1 1-3-3l7.912-7.912m4.155-5.154A12.5 12.5 0 0 0 5.461 2.958A1 1 0 0 0 5.58 4.71a22 22 0 0 1 6.318 3.393"/><path d="M17.7 3.7a1 1 0 0 0-1.4 0l-4.6 4.6a1 1 0 0 0 0 1.4l2.6 2.6a1 1 0 0 0 1.4 0l4.6-4.6a1 1 0 0 0 0-1.4z"/><path d="M19.686 8.314a12.5 12.5 0 0 1 1.356 10.225a1 1 0 0 1-1.751-.119a22 22 0 0 0-3.393-6.319"/></g></svg></router-link>
        <small class="text-center d-block mt-2 fw-bold">Minning</small>
      </div>
      <div class="col-3">
        <button style="height: 73px;" class="btn w-custom rounded-circle text-white fw-bold fs-3 bg-icon-up" @click="openFloat"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M3 3v16a2 2 0 0 0 2 2h16"/><path d="m19 9l-5 5l-4-4l-3 3"/></g></svg></button>
        <small class="text-center d-block mt-2 fw-bold">RPT</small>
      </div>
      <div class="col-3">
        <button style="height: 73px;" class="btn w-custom rounded-circle text-white fw-bold fs-3 bg-icon-up" @click="openFloat"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 21a9 9 0 1 0-7.605-4.185L3 21l4.185-1.395A8.96 8.96 0 0 0 12 21"/></svg></button>
        <small class="text-center d-block mt-2 fw-bold">Chat</small>
      </div>
    </div>
    <div class="mb-3 d-flex align-items-center">
      <small class="text-muted" style="width: 25%;">Pembayaran</small>
      <div class="pt-1" style="width: 75%;"><hr></div>
    </div>
    <!-- <div class="alert alert-info d-flex gap-3 my-3" role="alert">
      <i class="bi bi-bell-fill"></i>
      <marquee>Gunakan kesempatan emas sebaik mungkin 🔥🔥🔥</marquee>
    </div> -->
    <div class="row mb-3">
      <div class="col-3" @click="checkLogin('/pulsa')">
        <span style="height: 73px;" class="btn w-custom py-3 rounded-circle fw-bold fs-3 bg-icon-main d-flex align-items-center justify-content-center"><i class="bi bi-phone"></i></span>
        <small class="text-center d-block mt-2 fw-bold">Pulsa</small>
      </div>
      <div class="col-3" @click="checkLogin('/data')">
        <span style="height: 73px;" class="btn w-custom py-3 rounded-circle fw-bold fs-3 bg-icon-main d-flex align-items-center justify-content-center"><i class="bi bi-reception-4"></i></span>
        <small class="text-center d-block mt-2 fw-bold">Paket Data</small>
      </div>
      <div class="col-3">
        <button style="height: 73px;" class="btn w-custom py-3 rounded-circle fw-bold fs-3 bg-icon-main d-flex align-items-center justify-content-center" @click="openFloatWallet"><i class="bi bi-wallet"></i></button>
        <small class="text-center d-block mt-2 fw-bold">E-wallet</small>
      </div>
      <div class="col-3" @click="checkLogin('/tf')">
        <span style="height: 73px;" class="btn w-custom py-3 rounded-circle fw-bold fs-3 bg-icon-main d-flex align-items-center justify-content-center"><i class="bi bi-send-plus"></i></span>
        <small class="text-center d-block mt-2 fw-bold">Transfer</small>
      </div>
    </div>
    <div class="row">
      <div class="col-3" @click="checkLogin('/pln')">
        <span style="height: 73px;" class="btn w-custom py-3 rounded-circle fw-bold fs-3 bg-icon-main d-flex align-items-center justify-content-center"><i class="bi bi-lightning-fill"></i></span>
        <small class="text-center d-block mt-2 fw-bold">PLN</small>
      </div>
      <div class="col-3">
        <button style="height: 73px;" class="btn w-custom py-3 rounded-circle fw-bold fs-3 bg-icon-main d-flex align-items-center justify-content-center" @click="openFloatGame"><i class="bi bi-controller"></i></button>
        <small class="text-center d-block mt-2 fw-bold">Games</small>
      </div>
      <div class="col-3">
        <button style="height: 73px;" class="btn w-custom py-3 rounded-circle fw-bold fs-3 bg-icon-main d-flex align-items-center justify-content-center" @click="openFloat"><i class="bi bi-ticket-perforated-fill"></i></button>
        <small class="text-center d-block mt-2 fw-bold">Voucher</small>
      </div>
      <div class="col-3">
        <button style="height: 73px;" class="btn w-custom py-3 rounded-circle text-white fw-bold fs-3 bg-icon-main" @click="openFloat"><i class="bi bi-grid-fill"></i></button>
        <small class="text-center d-block mt-2 fw-bold">Lainnya</small>
      </div>
    </div>
    <div class="mb-3 d-flex align-items-center mt-3">
      <small class="text-muted" style="width: 25%;">Promosi</small>
      <div class="pt-1" style="width: 75%;"><hr></div>
    </div>
    <img src="../assets/promo.jpg" class="w-100" >
  </div>
  <!-- float game -->
  <div class="bg-float game">
    <div class="box w-100 p-4 bg-white">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="m-0 p-0">Topup Games.</h3>
        <i class="bi bi-x-circle fs-1 text-secondary" @click="closeFloatGame"></i>
      </div>
      <hr>
      <br>
      <div class="row">
        <div class="col-3">
          <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/games/ml.webp" width="105%" class="rounded">
          </button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">MLBB</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/games/ff.webp" width="105%" class="rounded">
          </button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Free fire</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/games/pubgm.webp" width="105%" class="rounded">
          </button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">PUBGM</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/games/pb.png" width="105%" class="rounded">
          </button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">PB</small>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-3">
          <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/games/valo.png" width="105%" class="rounded">
          </button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Valorant</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/games/cod.webp" width="105%" class="rounded">
          </button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">CODM</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/games/garena.jpg" width="105%" class="rounded border border-2">
          </button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Garena</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/games/aov.png" width="105%" class="rounded border border-2">
          </button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">AOV</small>
        </div>
      </div>
    </div>
  </div>
  <!-- float maintance -->
  <div class="bg-float float">
    <div class="box w-100 p-4 bg-light">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="m-0 p-0">Dalam Pengembangan.</h3>
        <i class="bi bi-x-circle fs-1 text-secondary" @click="closeFloat"></i>
      </div>
      <hr>
      <br>
      <img src="../assets/maintenance.gif" class="w-100">
    </div>
  </div>
  <!-- float wallet -->
  <div class="bg-float wallet">
    <div class="box w-100 p-4 bg-white">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="m-0 p-0">Topup wallet.</h3>
        <i class="bi bi-x-circle fs-1 text-secondary" @click="closeFloatWallet"></i>
      </div>
      <hr>
      <br>
      <div class="row">
        <div class="col-3">
          <router-link to="dana" class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/wallet/dana.jpeg" width="105%" class="rounded">
          </router-link>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Dana</small>
        </div>
        <div class="col-3">
          <router-link to="ovo" class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/wallet/ovo.jpg" width="105%" class="rounded">
          </router-link>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Ovo</small>
        </div>
        <div class="col-3">
          <router-link to="gopay" class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/wallet/gopay.png" width="105%" class="rounded">
          </router-link>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Gopay</small>
        </div>
        <div class="col-3">
          <router-link to="spay" class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
            <img src="../assets/wallet/sopi.jpeg" width="105%" class="rounded">
          </router-link>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Spay</small>
        </div>
      </div>
    </div>
  </div>
  <div class="pin-form">
    <div class="bg-white p-3 rounded" style="width: 80%;">
      <h4 class="mb-3">Masukan PIN Baru Anda</h4>
      <form @submit.prevent="submitPin">
        <div style="display: flex; gap: 10px; justify-content: center;">
          <input type="number" maxlength="1" class="pin-input" id="pin1" v-model="pinInputs[0]" autofocus>
          <input type="number" maxlength="1" class="pin-input" id="pin2" v-model="pinInputs[1]">
          <input type="number" maxlength="1" class="pin-input" id="pin3" v-model="pinInputs[2]">
          <input type="number" maxlength="1" class="pin-input" id="pin4" v-model="pinInputs[3]">
          <input type="number" maxlength="1" class="pin-input" id="pin5" v-model="pinInputs[4]">
          <input type="number" maxlength="1" class="pin-input" id="pin6" v-model="pinInputs[5]">
        </div>
        <button type="submit" class="btn btn-primary w-100 mt-3">Konfirmasi</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
  name: 'MainView',
  methods: {
    navcard(c){
      if(c == 1){
        document.querySelector(".text-nav-a1").classList.add("text-primary");
        document.querySelector(".text-nav-a2").classList.add("text-primary");
        document.querySelector(".text-nav-b1").classList.remove("text-primary");
        document.querySelector(".text-nav-b2").classList.remove("text-primary");
        document.querySelector(".text-nav-c1").classList.remove("text-primary");
        document.querySelector(".text-nav-c2").classList.remove("text-primary");
        document.querySelector(".text-nav-d1").classList.remove("text-primary");
        document.querySelector(".text-nav-d2").classList.remove("text-primary");
        document.querySelector(".text-nav-e1").classList.remove("text-primary");
        document.querySelector(".text-nav-e2").classList.remove("text-primary");

        document.querySelector(".text-nav-a1").classList.remove("text-secondary");
        document.querySelector(".text-nav-a2").classList.remove("text-secondary");
        document.querySelector(".text-nav-b1").classList.add("text-secondary");
        document.querySelector(".text-nav-b2").classList.add("text-secondary");
        document.querySelector(".text-nav-c1").classList.add("text-secondary");
        document.querySelector(".text-nav-c2").classList.add("text-secondary");
        document.querySelector(".text-nav-d1").classList.add("text-secondary");
        document.querySelector(".text-nav-d2").classList.add("text-secondary");
        document.querySelector(".text-nav-e1").classList.add("text-secondary");
        document.querySelector(".text-nav-e2").classList.add("text-secondary");

        this.viewSaldo = this.saldo
        this.viewRiwayat = "/main"
        this.viewText = "Saldo  Cash"
      }else if(c == 2){
        document.querySelector(".text-nav-a1").classList.remove("text-primary");
        document.querySelector(".text-nav-a2").classList.remove("text-primary");
        document.querySelector(".text-nav-b1").classList.add("text-primary");
        document.querySelector(".text-nav-b2").classList.add("text-primary");
        document.querySelector(".text-nav-c1").classList.remove("text-primary");
        document.querySelector(".text-nav-c2").classList.remove("text-primary");
        document.querySelector(".text-nav-d1").classList.remove("text-primary");
        document.querySelector(".text-nav-d2").classList.remove("text-primary");
        document.querySelector(".text-nav-e1").classList.remove("text-primary");
        document.querySelector(".text-nav-e2").classList.remove("text-primary");

        document.querySelector(".text-nav-a1").classList.add("text-secondary");
        document.querySelector(".text-nav-a2").classList.add("text-secondary");
        document.querySelector(".text-nav-b1").classList.remove("text-secondary");
        document.querySelector(".text-nav-b2").classList.remove("text-secondary");
        document.querySelector(".text-nav-c1").classList.add("text-secondary");
        document.querySelector(".text-nav-c2").classList.add("text-secondary");
        document.querySelector(".text-nav-d1").classList.add("text-secondary");
        document.querySelector(".text-nav-d2").classList.add("text-secondary");
        document.querySelector(".text-nav-e1").classList.add("text-secondary");
        document.querySelector(".text-nav-e2").classList.add("text-secondary");

        this.viewSaldo = this.point_1
        this.viewRiwayat = "/main"
        this.viewText = "TV (Token View)"
      }else if(c == 3){
        document.querySelector(".text-nav-a1").classList.remove("text-primary");
        document.querySelector(".text-nav-a2").classList.remove("text-primary");
        document.querySelector(".text-nav-b1").classList.remove("text-primary");
        document.querySelector(".text-nav-b2").classList.remove("text-primary");
        document.querySelector(".text-nav-d1").classList.remove("text-primary");
        document.querySelector(".text-nav-d2").classList.remove("text-primary");
        document.querySelector(".text-nav-e1").classList.remove("text-primary");
        document.querySelector(".text-nav-e2").classList.remove("text-primary");
        document.querySelector(".text-nav-c1").classList.add("text-primary");
        document.querySelector(".text-nav-c2").classList.add("text-primary");

        document.querySelector(".text-nav-a1").classList.add("text-secondary");
        document.querySelector(".text-nav-a2").classList.add("text-secondary");
        document.querySelector(".text-nav-b1").classList.add("text-secondary");
        document.querySelector(".text-nav-b2").classList.add("text-secondary");
        document.querySelector(".text-nav-d1").classList.add("text-secondary");
        document.querySelector(".text-nav-d2").classList.add("text-secondary");
        document.querySelector(".text-nav-e1").classList.add("text-secondary");
        document.querySelector(".text-nav-e2").classList.add("text-secondary");
        document.querySelector(".text-nav-c1").classList.remove("text-secondary");
        document.querySelector(".text-nav-c2").classList.remove("text-secondary");

        this.viewSaldo = this.voucher
        this.viewRiwayat = "/main"
        this.viewText = "Saldo  Voucher"
      }else if(c == 4){
        document.querySelector(".text-nav-a1").classList.remove("text-primary");
        document.querySelector(".text-nav-a2").classList.remove("text-primary");
        document.querySelector(".text-nav-b1").classList.remove("text-primary");
        document.querySelector(".text-nav-b2").classList.remove("text-primary");
        document.querySelector(".text-nav-c1").classList.remove("text-primary");
        document.querySelector(".text-nav-c2").classList.remove("text-primary");
        document.querySelector(".text-nav-e1").classList.remove("text-primary");
        document.querySelector(".text-nav-e2").classList.remove("text-primary");
        document.querySelector(".text-nav-d1").classList.add("text-primary");
        document.querySelector(".text-nav-d2").classList.add("text-primary");

        document.querySelector(".text-nav-a1").classList.add("text-secondary");
        document.querySelector(".text-nav-a2").classList.add("text-secondary");
        document.querySelector(".text-nav-b1").classList.add("text-secondary");
        document.querySelector(".text-nav-b2").classList.add("text-secondary");
        document.querySelector(".text-nav-c1").classList.add("text-secondary");
        document.querySelector(".text-nav-c2").classList.add("text-secondary");
        document.querySelector(".text-nav-e1").classList.add("text-secondary");
        document.querySelector(".text-nav-e2").classList.add("text-secondary");
        document.querySelector(".text-nav-d1").classList.remove("text-secondary");
        document.querySelector(".text-nav-d2").classList.remove("text-secondary");

        this.viewSaldo = this.rpt
        this.viewRiwayat = "/main"
        this.viewText = "RPT Ballance"
      }else if(c == 5){
        document.querySelector(".text-nav-a1").classList.remove("text-primary");
        document.querySelector(".text-nav-a2").classList.remove("text-primary");
        document.querySelector(".text-nav-b1").classList.remove("text-primary");
        document.querySelector(".text-nav-b2").classList.remove("text-primary");
        document.querySelector(".text-nav-c1").classList.remove("text-primary");
        document.querySelector(".text-nav-c2").classList.remove("text-primary");
        document.querySelector(".text-nav-d1").classList.remove("text-primary");
        document.querySelector(".text-nav-d2").classList.remove("text-primary");
        document.querySelector(".text-nav-e1").classList.add("text-primary");
        document.querySelector(".text-nav-e2").classList.add("text-primary");

        document.querySelector(".text-nav-a1").classList.add("text-secondary");
        document.querySelector(".text-nav-a2").classList.add("text-secondary");
        document.querySelector(".text-nav-b1").classList.add("text-secondary");
        document.querySelector(".text-nav-b2").classList.add("text-secondary");
        document.querySelector(".text-nav-c1").classList.add("text-secondary");
        document.querySelector(".text-nav-c2").classList.add("text-secondary");
        document.querySelector(".text-nav-d1").classList.add("text-secondary");
        document.querySelector(".text-nav-d2").classList.add("text-secondary");
        document.querySelector(".text-nav-e1").classList.remove("text-secondary");
        document.querySelector(".text-nav-e2").classList.remove("text-secondary");

        this.viewSaldo = this.point
        this.viewRiwayat = "/main"
        this.viewText = "UV  (Unit Value)"
      }
    },
    mine(){
      window.location.href = 'https://minning.tokoku.org?accessToken=' + this.token + '&userId=' + this.userId + "&code=123"
    },
    async submitPin() {
      // Gabungkan nilai dari semua input PIN
      const pin = this.pinInputs.join("");

      // Validasi jika semua digit sudah diisi
      if (pin.length !== 6) {
        Swal.fire({
          title: "PIN tidak lengkap",
          text: "Harap masukkan semua 6 digit PIN.",
          icon: "error",
        });
        return;
      }

      try {
        // Kirim data PIN ke server
        await axios.patch(
          `https://api.tokoku.org/public/api/users/${this.userId}/pin`,
          { pin: pin },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
          
        document.querySelector(".pin-form").classList.remove("d-flex-imp")    

        // Tampilkan notifikasi jika berhasil
        Swal.fire({
          title: "Berhasil",
          text: "PIN baru berhasil disimpan.",
          icon: "success",
        });

        // Tutup form PIN setelah berhasil
        document.querySelector(".pin-form").style.display = "none";
      } catch (error) {
        console.error("Error saving PIN:", error);
        Swal.fire({
          title: "Gagal",
          text: "Terjadi kesalahan saat menyimpan PIN.",
          icon: "error",
        });
      }
    },
    saveReferralCode() {
      // Hapus "@" di awal referralCode, jika ada
      if (this.referralCode && this.referralCode.startsWith("@")) {
        this.referralCode = this.referralCode.slice(1); // Menghapus karakter pertama
      }

      // Simpan referralCode di localStorage
      if (this.referralCode) {
        localStorage.setItem("referralCode", this.referralCode);
      }

      axios.get("https://api.tokoku.org/public/api/users", {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then((res) => {
          let berhasil = false;
          res.data.map((item) => {
            console.log(item);
            if (item.name === this.referralCode) {
              berhasil = true;
            }
          });

          if (berhasil) {
            axios.patch(
              `https://api.tokoku.org/public/api/users/${this.userId}/referral`,
              {
                referral: this.referralCode,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                },
              }
            )
              .then(() => {
                alert("success");
                axios
                  .get(`https://api.tokoku.org/public/api/users/${this.userId}`, {
                    headers: {
                      Authorization: `Bearer ${this.token}`,
                    },
                  })
                  .then((response) => {
                    console.log(response.data);
                    this.name = response.data.name;
                    this.rowStatus = response.data.row_status;
                    this.saldo = response.data.saldo;
                    this.rpt = response.data.rpt;
                    this.voucher = response.data.voucher;
                    this.referral = response.data.referral;

                    if (
                      response.data.pin == null ||
                      response.data.pin == ""
                    ) {
                      document
                        .querySelector(".pin-form")
                        .classList.add("d-flex-imp");
                    }
                  })
                  .catch((error) => {
                    console.error("Error fetching user data:", error);
                  });
              });
          } else {
            Swal.fire({
              title: "Referral Code Salah",
              text: "Dapatkan Refferal asli",
              icon: "error",
            });
          }
        });
    },
    loadReferralCode() {
      // Ambil referralCode dari localStorage jika ada
      const savedCode = localStorage.getItem("referralCode");
      if (savedCode) {
        this.referralCode = savedCode;
      }
    },
    openFloatGame(){
      if(this.token == "" && this.userId == ""){
        Swal.fire({
          title: 'Belum Login!',
          text: 'Login terlebih dahulu.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Tutup',
          cancelButtonText: 'Login',
          referralCode: "",       // Untuk menyimpan kode referral
          agreementChecked: false // Checkbox "Setuju"
        }).then((result) => {
          if (result.isConfirmed) {
            // Aksi untuk tombol 'Try Again'
            console.log('User clicked Try Again');
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Aksi untuk tombol 'Go to Login'
            this.$router.push("/login");
          }
        });
      }else {
      document.querySelector(".game").classList.add("on-bg-float");
      }
    },
    closeFloatGame(){
      document.querySelector(".game").classList.remove("on-bg-float");
    },
    openFloat(){
      if(this.token == "" && this.userId == ""){
        Swal.fire({
          title: 'Belum Login!',
          text: 'Login terlebih dahulu.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Tutup',
          cancelButtonText: 'Login',
        }).then((result) => {
          if (result.isConfirmed) {
            // Aksi untuk tombol 'Try Again'
            console.log('User clicked Try Again');
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Aksi untuk tombol 'Go to Login'
            this.$router.push("/login");
          }
        });
      }else {
      document.querySelector(".float").classList.add("on-bg-float");
      }
    },
    closeFloat(){
      document.querySelector(".float").classList.remove("on-bg-float");
    },
    openFloatWallet(){
      if(this.token == "" && this.userId == ""){
        Swal.fire({
          title: 'Belum Login!',
          text: 'Login terlebih dahulu.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Tutup',
          cancelButtonText: 'Login',
        }).then((result) => {
          if (result.isConfirmed) {
            // Aksi untuk tombol 'Try Again'
            console.log('User clicked Try Again');
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Aksi untuk tombol 'Go to Login'
            this.$router.push("/login");
          }
        });
      }else {
      document.querySelector(".wallet").classList.add("on-bg-float");
      }
    },
    closeFloatWallet(){
      document.querySelector(".wallet").classList.remove("on-bg-float");
    },
    formatRupiah(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value); 
    },
    checkLogin(url){
      if(this.token == "" && this.userId == ""){
        Swal.fire({
          title: 'Belum Login!',
          text: 'Login terlebih dahulu.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Tutup',
          cancelButtonText: 'Login',
        }).then((result) => {
          if (result.isConfirmed) {
            // Aksi untuk tombol 'Try Again'
            console.log('User clicked Try Again');
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Aksi untuk tombol 'Go to Login'
            this.$router.push("/login");
          }
        });
      }else {
        this.$router.push(url);
      }
    },
    checkLoginHref(url){
      if(this.token == "" && this.userId == ""){
        Swal.fire({
          title: 'Belum Login!',
          text: 'Login terlebih dahulu.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Tutup',
          cancelButtonText: 'Login',
        }).then((result) => {
          if (result.isConfirmed) {
            // Aksi untuk tombol 'Try Again'
            console.log('User clicked Try Again');
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Aksi untuk tombol 'Go to Login'
            this.$router.push("/login");
          }
        });
      }else {
        window.location.href = url;
      }
    },
  },
  data(){
    return {
      name: "Anonymous",
      viewSaldo: 0,
      viewText:  "Saldo Cash",
      viewRiwayat: "/main",
      saldo: 0,
      voucher: 0,
      rpt: 0,
      point: 0,
      point_1: 0,
      rawStatus: 0,
      token: "",
      userId: "",
      referral: null,
      pin: null,
      pinInputs: ["", "", "", "", "", ""],
    }
  },
  mounted() {   
    const inputs = document.querySelectorAll('.pin-input');


    inputs.forEach((input, index) => {
        input.addEventListener('keydown', (e) => {
            if (e.key === "Backspace") {
                input.value = '';
                if (index > 0) inputs[index - 1].focus();
            } else if (e.key >= 0 && e.key <= 9) {
                input.value = '';
            } else if (e.key !== "Tab") {
                e.preventDefault();
            }
        });

        input.addEventListener('input', () => {
            if (input.value.length > 0 && index < inputs.length - 1) {
                inputs[index + 1].focus();
            }
        });
    });

    this.loadReferralCode();
    
    // Fungsi untuk mengecek apakah hari ini adalah hari Kamis
    const isTodayThursday = () => {
        const today = new Date(); // Mendapatkan tanggal hari ini
        const dayOfWeek = today.getDay(); // Mendapatkan nomor hari dalam seminggu (0 untuk Minggu, 1 untuk Senin, dst.)
        return dayOfWeek === 5; // 4 mewakili hari Kamis
    };

    // Fungsi untuk menyimpan data ke localStorage
    const saveToStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    // Fungsi untuk mengambil data dari localStorage
    const getFromStorage = (key) => {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    };

    // Fungsi untuk menghapus data dari localStorage
    const removeFromStorage = (key) => {
        localStorage.removeItem(key);
    };

    // Variabel untuk melacak apakah pesan sudah ditampilkan
    const messageKey = "messageShown";
    const messageShown = getFromStorage(messageKey);

      // Verifikasi token dan user ID
      const token = Cookies.get('auth_token');
      const userId = Cookies.get('user_id');


    const accessTokenParams = this.$route.query.accessToken;
    const userIdParams = this.$route.query.userId;
    
    // if ((!token || !userId) || (!accessTokenParams || !userIdParams)) {
    if (!token || !userId) {
      this.token = "";
      this.userId = "";
      this.$router.push("/");
    } else {        
      // Menggunakan fungsi untuk memberi tahu apakah hari ini Kamis
      if (isTodayThursday() && !messageShown) {
          axios.patch(`https://api.tokoku.org/public/api/users/${userId}/row-status`, {
            row_status: 0
          })
          console.log("Hari ini adalah hari Kamis!");
          saveToStorage(messageKey, true); // Tandai bahwa pesan sudah ditampilkan
      } else if (!isTodayThursday()) {
          removeFromStorage(messageKey); // Reset jika bukan hari Kamis
          console.log("Hari ini bukan hari Kamis.");
      }

      if(accessTokenParams && userIdParams){
        this.userId = userIdParams;
        this.token = accessTokenParams;       
      }else {
        this.userId = userId;
        this.token = token;       
      }

      axios.get(`https://api.tokoku.org/public/api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
          console.log(response.data);
          this.name = response.data.name;
          this.rowStatus = response.data.row_status;
          this.saldo = response.data.saldo;
          this.rpt = response.data.rpt;
          this.point = response.data.point;
          this.point_1 = response.data.point_1;
          this.voucher = response.data.voucher;
          this.referral = response.data.referral;
          this.viewSaldo = response.data.saldo

          if(!this.referral){
            document.querySelector(".bg-ref").classList.add("d-flex")
          }

          if(response.data.pin == null){
            document.querySelector(".pin-form").classList.add("d-flex-imp")
          }
      })
      .catch((error) => {
          console.error("Error fetching user data:", error);
      });
    }
  },

}
</script>

<style scoped>
.col-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.w-custom {
  width: 73px;
}
.home {
  background-image: url(../assets/bg-main.jpg);
  background-size: 110%;
  background-position: center;
  width: 100%;
  height: 180px;
  border-radius: 0 0 15px 15px;
}

</style>

<style>
.modal-backdrop.fade.show {
  z-index: 0;
}

.box-ref {
  width: 80%;
  padding: 20px;
  border-radius: 12px;
}

.bg-ref {
  background-color: rgba(0, 0, 0, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: none;
  justify-content: center;
  align-items: center;
}

.bg-icon-up {
  background:#FFBE24 !important;
  color: white;
}

.bg-icon-up:hover {
  background:#FFBE24 !important;
  color: white !important;
}

.bg-icon-main {
  background: linear-gradient(
    to right, 
    #FFBE24 0%,  /* Warna kuning pada 0% */
    #FF9600 50%, /* Warna oranye pada 50% */
    #FFBE24 100% /* Warna kuning pada 100% */
  );
  color: white !important;
}

.bg-icon-main:hover {
  background: linear-gradient(
    to right, 
    #FFBE24 0%,  /* Warna kuning pada 0% */
    #FF9600 50%, /* Warna oranye pada 50% */
    #FFBE24 100% /* Warna kuning pada 100% */
  ) !important;
  color: white !important;
}

.d-flex-imp {
  display: flex !important;
}

.pin-form {
  position: fixed;
  top: 0;left: 0;right: 0;bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  display: none;
  justify-content: center;
  align-items: center;
}

.pin-input {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.box-info {
  width: 90%;
  position: absolute;
  left: 5%;
  top: 10vh;
}

@media (orientation: landscape) {
  .box-info {
    width: 90%;
    position: absolute;
    left: 5%;
    top: 20vh;
  }
}

.bg-float {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9;
  top: 0;bottom: 0;left: 0;right: 0;
}

.on-bg-float {
  display: block;
}

.box {
  position: absolute;
  bottom: 0;
  border-radius: 20px 20px 0 0 ;
  height: 70dvh;
}

.gap-fix-1 {
  min-height: calc(100dvh - 80px);
}
</style>
