<template>
  <div class="hp">
    <router-view/>
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fffef9;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .hp {
    width: 420px;
    margin: auto;
  }

}

input {
  border: 1px solid #6c757d !important;
}

/* Force landscape orientation */
@media (orientation: landscape) {
  .must-rotate {
    display: none;
  }

  .hp {
    width: 100% !important;
    height: 100dvh !important;
  }
}
</style>
