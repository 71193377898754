import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisView from '../views/RegisView.vue'
import LoginView from '../views/LoginView.vue'
import MainView from '../views/MainView.vue'
import ProfilView from '../views/ProfilView.vue'

import PulsaView from '../views/pages/PulsaView.vue'
import DataView from '../views/pages/DataView.vue'
import PLNView from '../views/pages/PLNView.vue'
import TFView from '../views/pages/TFView.vue'
import TopupView from '../views/pages/TopupView.vue'
import DanaView from '../views/pages/DanaView.vue'
import OvoView from '../views/pages/OvoView.vue'
import GopayView from '../views/pages/GopayView.vue'
import SpayView from '../views/pages/SpayView.vue'

import MinningView from '@/views/minning/MinningView.vue'
import PowerView from '@/views/minning/PowerView.vue'
import MineView from '@/views/minning/MineView.vue'
import CoinView from '@/views/minning/CoinView.vue'

const routes = [
  {
    path: '/games/mine',
    name:'mine',
    component: MineView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profil',
    name: 'profil',
    component: ProfilView
  },
  {
    path: '/regis',
    name: 'register',
    component: RegisView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/main',
    name: 'main',
    component: MainView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/pulsa',
    name: 'pulsa',
    component: PulsaView
  },
  {
    path: '/data',
    name: 'data',
    component: DataView
  },
  {
    path: '/pln',
    name: 'pln',
    component: PLNView
  },
  {
    path: '/tf',
    name: 'tf',
    component: TFView
  },
  {
    path: '/voucher',
    name: 'topup',
    component: TopupView
  },
  {
    path: '/dana',
    name: 'dana',
    component: DanaView
  },
  {
    path: '/ovo',
    name: 'ovo',
    component: OvoView
  },
  {
    path: '/gopay',
    name: 'gopay',
    component: GopayView
  },
  {
    path: '/spay',
    name: 'spay',
    component: SpayView
  },
  {
    path: '/minning',
    name:'minning',
    component: MinningView,
  },
  {
    path: '/games/power',
    name:'power',
    component: PowerView,
  },
  {
    path: '/games/coin',
    name:'coin',
    component: CoinView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
