<template>
  <!-- Pembungkus utama komponen -->
  <div>
    <!-- Bagian overlay jika orientasi portrait -->
    <div class="must-rotate" v-if="isPortrait">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m12.823 18.258l-7.12-7.12Q5.226 10.66 5.226 10t.479-1.138l3.158-3.158Q9.34 5.225 10 5.225t1.139.479l7.119 7.119q.479.479.479 1.139t-.48 1.138L15.1 18.258q-.479.478-1.138.478q-.66 0-1.139-.478M12.456 2.04l3.098 3.098q.14.14.15.344t-.15.363t-.354.16t-.354-.16l-4.019-4.019q-.204-.204-.114-.46t.38-.311q.234-.031.453-.044T12 1q2.283 0 4.278.86q1.995.861 3.498 2.364t2.364 3.498T23 12q0 .214-.143.357t-.357.143t-.357-.143T22 12q0-2.006-.744-3.784q-.745-1.78-2.028-3.118Q17.944 3.76 16.2 2.943t-3.744-.902m-.912 19.919l-3.098-3.098q-.14-.14-.15-.345q-.01-.203.15-.363t.354-.16t.354.16l4.019 4.019q.204.204.115.463q-.09.258-.38.308q-.235.031-.454.044T12 23q-2.263 0-4.268-.86q-2.005-.861-3.508-2.364t-2.363-3.508T1 12q0-.213.143-.357T1.5 11.5t.357.143T2 12q0 2.006.744 3.785q.744 1.778 2.028 3.117T7.8 21.057t3.744.903"
          />
        </svg>
        <small class="text-center text-light d-flex mt-2">
          Please rotate<br />
          your device
        </small>
      </div>
    </div>

    <!-- Elemen UI Bagian Kiri-Bawah (Jar Energi) -->
    <span class="box3 rounded shadow-custom">
      <img src="../../assets/minning/jar.png" class="w-100" alt="Jar" />
      <!-- Teks persentase progress -->
      <h1 class="energi text-center w-100">{{ Math.floor(progress) }}%</h1>
    </span>

    <!-- Elemen UI untuk menampilkan total energi -->
    <span
      class="box4 bg-blur px-3 rounded border border-2 border-light d-flex shadow-custom"
    >
      <h5 style="text-shadow: 0 0 5px black" class="m-0 p-0">
        UV {{ energy }}
        <i class="bi bi-lightning-charge-fill text-warning"></i>
      </h5>
    </span>

    <!-- Elemen UI untuk Mute Button -->
    <span
      class="box5 bg-blur px-3 rounded border border-2 border-light d-flex shadow-custom"
    >
      <h5 style="text-shadow: 0 0 5px black" class="m-0 p-0">
        <div id="mute-button" class="mute-button" @click="toggleMuteAudio">
          {{ isMuted ? '🔇' : '🔊' }}
        </div>
      </h5>
    </span>

    <!-- Elemen UI menampilkan Boar -->
    <span
      class="box border bg-blur border-2 border-light shadow-custom rounded"
    >
      <img
        ref="boarImg"
        src="../../assets/minning/boar/run/wr1.png"
        id="boar"
        class="w-100"
        alt="Boar"
      />
    </span>

    <!-- Elemen UI menampilkan Lilin -->
    <span
      class="box2 border bg-blur border-2 border-light shadow-custom rounded"
      ref="lilinContainer"
    >
      <img src="../../assets/minning/lilin.png" class="h-100" alt="Lilin" />
      <img src="../../assets/minning/lilin.png" class="h-100" alt="Lilin" />
      <img src="../../assets/minning/lilin.png" class="h-100" alt="Lilin" />
      <img src="../../assets/minning/lilin.png" class="h-100" alt="Lilin" />
      <img src="../../assets/minning/lilin.png" class="h-100" alt="Lilin" />
    </span>

    <!-- Container tempat bergeraknya objek -->
    <div id="container" ref="container">
      <img
        ref="movingObject"
        src="../../assets/minning/pin.png"
        alt="pin"
        class="movingObject"
      />
    </div>

    <!-- Tombol Start/Pause -->
    <button class="btn-start" @click="toggleGame">
      {{ isPlaying ? 'Pause' : 'Start' }}
    </button>

    <!-- Tombol Kembali -->
    <router-link
      to="/minning"
      class="btn-start"
      id="back"
    >
      Kembali
    </router-link>

    <!-- Audio -->
    <audio
      ref="audioPlayer"
      :src="audioSrc"
      loop
    ></audio>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie';

export default {
  name: "ClaimEnergy",
  data() {
    return {
      // State energy diisi dari server (response.data.point)
      energy: 0,
      // Progress bar (0-100)
      progress: 0,

      // Apakah game sedang berjalan?
      isPlaying: false,
      // Status mute/unmute audio
      isMuted: false,

      // Intervals
      moveInterval: null,
      progressInterval: null,
      boarAnimationInterval: null,
      lilinInterval: null,
      pointClaimInterval: null,

      // Animasi boar
      runFrame: 1,
      isDizzy: false,

      // Batas max energy
      maxEnergy: 1000,

      // Query params
      accessToken: "",
      userId: "",
      code: "",

      // Sumber audio
      audioSrc: require("../../assets/minning/run.mp3"),
    };
  },
  computed: {
    /**
     * Mengecek apakah perangkat dalam orientasi portrait
     */
    isPortrait() {
      return window.matchMedia("(orientation: portrait)").matches;
    },
  },
  mounted() {
    // Ambil parameter dari URL (jika masih digunakan)
    this.accessToken = Cookies.get("auth_token") || "";
    this.userId = Cookies.get("user_id") || "";
    this.code = Cookies.get("code") || "";

    // Ambil data user awal (energi) dari server
    this.fetchUserData();

    // Ambil progress (bukan energy) dari localStorage jika ada
    const storedProgress = localStorage.getItem("progress");
    if (storedProgress) {
      this.progress = parseFloat(storedProgress);
    }

    // Set posisi awal objek
    this.setInitialObjectPosition();

    // Contoh: hapus 1 lilin setiap 1 jam, berhenti setelah 5 jam
    let timelilin = setInterval(() => {
      this.removeOneLilin();
    }, 60 * 60 * 1000); // 1 jam

    setTimeout(() => {
      clearInterval(timelilin);
    }, 60 * 60 * 5000); // 5 jam
  },
  methods: {
    /**
     * Ambil data user (poin = energy) dari API
     */
    async fetchUserData() {
      if (!this.accessToken || !this.userId) {
        console.error("fetchUserData -> Token atau userId tidak ditemukan!");
        return;
      }
      try {
        const response = await axios.get(
          `https://api.tokoku.org/public/api/users/${this.userId}`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        // Nilai response.data.point kita set jadi this.energy
        this.energy = response.data.point || 0;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    /**
     * Inisialisasi posisi objek .movingObject secara acak
     */
    setInitialObjectPosition() {
      const object = this.$refs.movingObject;
      const { x, y } = this.getRandomPosition();
      object.style.left = `${x}px`;
      object.style.top = `${y}px`;
    },

    /**
     * Jalankan / jeda game
     */
    toggleGame() {
      this.isPlaying = !this.isPlaying;
      const audioPlayer = this.$refs.audioPlayer;

      if (this.isPlaying) {
        // Start
        if (!this.isMuted) {
          audioPlayer.play();
        }
        this.startMovingObject();
        this.startProgressUpdate();
        this.startBoarAnimation();
        this.startLilinInterval();
        this.startPointClaim();
      } else {
        // Pause
        audioPlayer.pause();
        this.stopMovingObject();
        this.stopProgressUpdate();
        this.stopBoarAnimation();
        this.stopLilinInterval();
        this.stopPointClaim();
      }
    },

    /**
     * Gerakkan objek setiap 1 detik
     */
    startMovingObject() {
      this.moveInterval = setInterval(() => {
        this.startMoving();
      }, 1000);
    },
    stopMovingObject() {
      if (this.moveInterval) clearInterval(this.moveInterval);
    },

    /**
     * Update progress bar tiap detik
     */
    startProgressUpdate() {
      this.progressInterval = setInterval(() => {
        this.updateProgress();
      }, 1000);
    },
    stopProgressUpdate() {
      if (this.progressInterval) clearInterval(this.progressInterval);
    },

    /**
     * Animasikan boar
     */
    startBoarAnimation() {
      const boar = this.$refs.boarImg;
      this.boarAnimationInterval = setInterval(() => {
        if (!this.isDizzy) {
          boar.src = require(`../../assets/minning/boar/run/wr${this.runFrame}.png`);
          this.runFrame = this.runFrame === 7 ? 1 : this.runFrame + 1;
        }
      }, 100);
    },
    stopBoarAnimation() {
      if (this.boarAnimationInterval) clearInterval(this.boarAnimationInterval);
    },

    /**
     * Tambah lilin setiap 1 jam
     */
    startLilinInterval() {
      const lilinContainer = this.$refs.lilinContainer;
      this.lilinInterval = setInterval(() => {
        const img = document.createElement("img");
        img.src = require("../../assets/minning/lilin.png");
        img.className = "h-100";
        lilinContainer.appendChild(img);
      }, 3600000);
    },
    stopLilinInterval() {
      if (this.lilinInterval) clearInterval(this.lilinInterval);
    },

    /**
     * Dapatkan posisi acak di container
     */
    getRandomPosition() {
      const container = this.$refs.container;
      const object = this.$refs.movingObject;
      const containerRect = container.getBoundingClientRect();
      const maxX = containerRect.width - object.offsetWidth;
      const maxY = containerRect.height - object.offsetHeight;
      return {
        x: Math.random() * maxX,
        y: Math.random() * maxY,
      };
    },

    /**
     * Animasi perpindahan objek
     */
    moveObject(start, end) {
      const object = this.$refs.movingObject;
      const distanceX = end.x - start.x;
      const distanceY = end.y - start.y;
      const duration = 1000;
      let startTime = null;

      function animate(time) {
        if (!startTime) startTime = time;
        const progress = (time - startTime) / duration;
        const currentX = start.x + distanceX * progress;
        const currentY = start.y + distanceY * progress;

        object.style.left = `${currentX}px`;
        object.style.top = `${currentY}px`;

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      }

      requestAnimationFrame(animate);
    },

    /**
     * Panggil moveObject() ke posisi acak
     */
    startMoving() {
      const object = this.$refs.movingObject;
      const startPos = {
        x: parseFloat(object.style.left) || 0,
        y: parseFloat(object.style.top) || 0,
      };
      const endPos = this.getRandomPosition();
      this.moveObject(startPos, endPos);
    },

    /**
     * Update progress/energi tiap detik
     * Jika progress >= 100 => +9 energy, reset progress = 0
     */
    updateProgress() {
      if (this.progress < 100 && this.energy < this.maxEnergy) {
        this.progress += 1.67;
        localStorage.setItem("progress", this.progress.toString());

        // Tambah energi jika progress penuh
        if (this.progress >= 100) {
          this.energy += 9;
          this.progress = 0;
          this.patchEnergyToServer();
        }
      } else {
        // Reset progress
        this.progress = 0;
        localStorage.setItem("progress", this.progress.toString());
        this.patchEnergyToServer();
      }
    },

    /**
     * PATCH energi ke server
     */
    async patchEnergyToServer() {
      if (!this.accessToken || !this.userId) return;
      try {
        await axios.patch(
          `https://api.tokoku.org/public/api/users/${this.userId}/point`,
          { point: Math.floor(this.energy) },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.error("Error patching energy:", error);
      }
    },

    /**
     * Hapus 1 lilin
     */
    removeOneLilin() {
      const lilinContainer = this.$refs.lilinContainer;
      if (lilinContainer.children.length > 0) {
        lilinContainer.removeChild(lilinContainer.lastElementChild);
      }
      // Kalau lilin habis, boar dizzy
      if (lilinContainer.children.length === 0 && !this.isDizzy) {
        this.isDizzy = true;
        this.stopMovingObject();
        this.stopProgressUpdate();
      }
    },

    /**
     * Mute/unmute audio
     */
    toggleMuteAudio() {
      const audioPlayer = this.$refs.audioPlayer;
      this.isMuted = !this.isMuted;
      audioPlayer.muted = this.isMuted;
    },

    /**
     * Interval klaim poin setiap 5 menit
     */
    startPointClaim() {
      this.stopPointClaim();
      this.pointClaimInterval = setInterval(() => {
        this.fetchAndClaimPoint();
      }, 300000);
    },
    stopPointClaim() {
      if (this.pointClaimInterval) clearInterval(this.pointClaimInterval);
    },

    /**
     * GET poin user, +0.1 ke server
     */
    async fetchAndClaimPoint() {
      if (!this.accessToken || !this.userId) return;
      try {
        // Ambil poin user
        const fetchResponse = await axios.get(
          `https://api.tokoku.org/public/api/users/${this.userId}`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        let currentPoint = fetchResponse.data.point || 0;
        currentPoint += 0.1;

        // Lakukan klaim
        const claimResponse = await axios.post(
          `https://api.tokoku.org/public/api/users/${this.userId}/point-claim`,
          { point: currentPoint },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (claimResponse.data && claimResponse.data.success) {
          // Perbarui energy di UI
          this.energy = currentPoint;
        }
      } catch (error) {
        console.error("Error fetching and claiming point:", error);
      }
    },
  },
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

/* Reset dasar */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Wadah utama */
#container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
  background-image: url("../../assets/minning/bg.jpg");
  background-size: 250px;
  overflow: hidden;
}

.movingObject {
  position: absolute;
  width: 100px;
}

.box {
  width: 95px;
  height: 60px;
  padding: 10px;
  position: fixed;
  display: flex;
  align-items: center;
  top: 15px;
  left: 40px;
  z-index: 99;
  background-color: white;
}

.box2 {
  height: 60px;
  padding: 10px;
  position: fixed;
  display: flex;
  align-items: center;
  top: 15px;
  left: 180px;
  z-index: 99;
  background-color: white;
}

.box3 {
  width: 120px;
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 99;
}

.box4 {
  position: fixed;
  display: flex;
  align-items: center;
  top: 15px;
  right: 95px;
  z-index: 99;
  height: 60px;
  font-family: "Lilita One", sans-serif;
  color: white;
}

.box5 {
  position: fixed;
  display: flex;
  align-items: center;
  top: 15px;
  right: 15px;
  z-index: 99;
  height: 60px;
  font-family: "Lilita One", sans-serif;
  color: white;
}

.shadow-custom {
  filter: drop-shadow(0px 0px 4px black);
}

.energi {
  position: absolute;
  top: 55px;
  left: 0;
  font-family: "Lilita One", sans-serif;
  color: white;
}

.bg-blur {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
}

.must-rotate {
  display: none;
}

/* Force landscape orientation */
@media (orientation: portrait) {
  .must-rotate {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    font-size: 24px;
    z-index: 1000;
  }
}

.btn-start {
  width: 250px;
  height: 80px;
  background-image: url("../../assets/minning/btn.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 20px;
  padding-bottom: 5px;
  text-decoration: none;
  justify-content: center;
  align-items: end;
}

#back {
  right: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
