<template>
  <div class="login bg-white p-3 pt-5">
    <h3 class="fw-bold text-center">Welcome to RPT App</h3>
    <small class="d-block text-center">Login untuk masuk ke halaman utama</small>
    <img src="@/assets/coin.png" alt="earth" width="100%" style="transform: scaleX(-1);">
    <form @submit.prevent="loginUser">
      <div class="mb-3">
        <label for="email" class="form-label">Email Anda</label>
        <input type="email" class="form-control" id="email" v-model="form.email">
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <div class="input-group">
          <input id="password-field" :type="passwordFieldType" class="form-control" v-model="form.password">
          <span class="input-group-text text-secondary toggle-password" @click="togglePasswordVisibility">
            <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </span>
        </div>
      </div>
      <button type="submit" class="btn w-100 btn-lg btn-primary">Submit</button>
      <router-link to="/" class="btn w-100 btn-lg btn-outline-secondary mt-2">Kembali</router-link>
    </form>
    <p class="mt-3">Belum Punya akun? <router-link to="/regis">Daftar</router-link></p>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
  name: 'LoginView',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      passwordFieldType: 'password',
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async loginUser() {
      try {
        const response = await axios.post('https://api.tokoku.org/public/api/login', this.form);

        // Simpan token dan ID user ke cookies
        Cookies.set('auth_token', response.data.access_token, { expires: 7 }); // Token valid 7 hari
        Cookies.set('user_id', response.data.user.id, { expires: 7 });

        // Tampilkan pesan sukses dan arahkan ke dashboard
        Swal.fire({
          title: 'Login Successful!',
          text: 'You are now logged in.',
          icon: 'success',
          confirmButtonText: 'Go to Dashboard',
        }).then(() => {
          this.$router.push('/main');
        });
      } catch (error) {
        Swal.fire({
          title: 'Login Failed',
          text: 'Invalid email or password.',
          icon: 'error',
          confirmButtonText: 'Try Again',
        });
      }
    },
  },
};
</script>

<style>
  .login {
    width: 100%;
    min-height: 100dvh;
  }

  .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
    padding-right: 30px;
  }

  .container {
    padding-top: 50px;
    margin: auto;
  }
</style>
