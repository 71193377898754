<template>
    <div class="must-rotate">
        <div
            style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            "
        >
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            >
            <path
                fill="currentColor"
                d="m12.823 18.258l-7.12-7.12Q5.226 10.66 5.226 10t.479-1.138l3.158-3.158Q9.34 5.225 10 5.225t1.139.479l7.119 7.119q.479.479.479 1.139t-.48 1.138L15.1 18.258q-.479.478-1.138.478q-.66 0-1.139-.478M12.456 2.04l3.098 3.098q.14.14.15.344t-.15.363t-.354.16t-.354-.16l-4.019-4.019q-.204-.204-.114-.46t.38-.311q.234-.031.453-.044T12 1q2.283 0 4.278.86q1.995.861 3.498 2.364t2.364 3.498T23 12q0 .214-.143.357t-.357.143t-.357-.143T22 12q0-2.006-.744-3.784q-.745-1.78-2.028-3.118Q17.944 3.76 16.2 2.943t-3.744-.902m-.912 19.919l-3.098-3.098q-.14-.14-.15-.345q-.01-.203.15-.363t.354-.16t.354.16l4.019 4.019q.204.204.115.463q-.09.258-.38.308q-.235.031-.454.044T12 23q-2.263 0-4.268-.86q-2.005-.861-3.508-2.364t-2.363-3.508T1 12q0-.213.143-.357T1.5 11.5t.357.143T2 12q0 2.006.744 3.785q.744 1.778 2.028 3.117T7.8 21.057t3.744.903"
            />
            </svg>
            <small style="margin-top: 20px; text-align: center"
            >Please rotate<br />your device</small
            >
        </div>
    </div>
    
    <div class="game-dashboard">
    <router-link to="/main"
        class="back bg-blur border-2 border-light shadow-custom rounded"
        id="backButton"
    >
        <svg
        width="50px"
        height="30px"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            fill="#ffffff"
            d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
        />
        <path
            fill="#ffffff"
            d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
        />
        </svg>
    </router-link>
    <div class="energi">
        <div class="progress-energi"></div>
    </div>
    <div class="RPT">
        <div class="progress-rpt">
        <span id="rpt">0</span>
        </div>
    </div>
    <div class="wrapper-content">
        <div class="wrapper-billboard">
        <div class="task">
            <div class="box2">
            <!-- <div class="wrapper-list-task">
                <div class="list">
                <h6 id="username">Login / Register</h6>
                <small id="user-email">Lorem ipsum dolor sit amet.</small>
                <h6 id="user-id">ID: Loading...</h6>
                </div>
            </div> -->
            <div class="wrapper-list-task">
                <div class="list mb-3">
                <h6>Task Daily</h6>
                </div>
                <div class="list mb-3">
                <h6>Refferal</h6>
                </div>
                <div class="list mb-3">
                <h6>Power Maker</h6>
                </div>
                <div class="list mb-3">
                <h6>Minning</h6>
                </div>
                <div class="list mb-3">
                <h6>Screen Task</h6>
                </div>
            </div>
            <!--<h1 class="judul">TASK</h1>-->
            </div>
        </div>
        <div class="task">
            <div class="box2">
            <div>
                <img
                class="list-image w-100"
                src="../../assets/minning/Tebus Murah Babi Ngepet (Horizontal).jpg"
                alt="banner"
                />
            </div>
            <!--<h1 class="countdown" id="countdown"></h1>-->
            </div>
        </div>
        </div>
        <div class="box-game menus">
        <div class="wrapper-feature">
            <router-link to="/games/power" class="icon" id="babi">
            <div class="icon">
                <div class="menu-icon2">
                <img
                    src="@/assets/img/a.jpg"
                    width="90px"
                    style="border-radius: 45px"
                />
                </div>
                <h5>UV Maker</h5>
            </div>
            </router-link>
            <router-link to="/games/mine" class="icon" id="minning">
            <div class="icon">
                <div class="menu-icon2">
                <img
                    src="@/assets/img/minning.webp"
                    width="90px"
                    style="border-radius: 45px"
                />
                </div>
                <h5>TV Maker</h5>
            </div>
            </router-link>
            <!--<a href="minning.html" class="icon" id="claim">-->
            <router-link to="/games/coin" class="icon" id="claim">
            <div class="icon">
                <div class="menu-icon2">
                <img
                    src="@/assets/img/coin.webp"
                    width="90px"
                    style="border-radius: 45px"
                />
                </div>
                <h5>Request Budget</h5>
            </div>
            </router-link>
        </div>
        </div>
    </div>
    </div>
</template>

<script>
import Cookies from "js-cookie"; // Import library js-cookie

export default {
  name: "GameDashboard",
  mounted() {
    this.fetchUserData();
    this.fetchEnergyData();
  },
  methods: {
    getCookie(name) {
      // Mengambil nilai cookie berdasarkan nama
      return Cookies.get(name);
    },
    async fetchUserData() {
      const accessToken = this.getCookie("auth_token"); // Ambil accessToken dari cookies
      const userId = this.getCookie("user_id"); // Ambil userId dari cookies

      if (!accessToken || !userId) {
        console.error("Token atau userId tidak ditemukan di cookies!");
        return;
      }

      try {
        const response = await fetch(
          `https://api.tokoku.org/public/api/users/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        document.getElementById("rpt").innerText = data.point_1 || 0;
        document.getElementById("username").innerText = data.username || "No Name";
        document.getElementById("user-email").innerText = data.email || "No Email";
        document.getElementById("user-id").innerText = `ID: ${data.id}`;
      } catch (error) {
        console.error("Gagal memuat data pengguna:", error);
      }
    },
    async fetchEnergyData() {
      const accessToken = this.getCookie("auth_token");
      const userId = this.getCookie("user_id");

      if (!accessToken || !userId) {
        console.error("Token atau userId tidak ditemukan di cookies!");
        return;
      }

      try {
        const response = await fetch(
          `https://api.tokoku.org/public/api/users/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        const energyPoints = data.point || 0;
        const maxEnergy = 1000;
        const energyPercentage = Math.min(
          (energyPoints / maxEnergy) * 100,
          100
        );

        const progressBar = document.querySelector(".progress-energi");
        progressBar.style.width = energyPercentage + "%";
        progressBar.innerText = `${Math.round(energyPercentage)}%`;
      } catch (error) {
        console.error("Gagal memuat data energi:", error);
      }
    },
  },
};
</script>

<style scoped>
.menu-icon2 {
  width: 100%;
  height: 150px;
  background-image: url(../../assets/img/icon.png);
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.icon {
  width: 150px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  text-decoration: none;
}

.menus {
  display: flex; /* Menjadikan item dalam .menus berjejer horizontal */
  flex-wrap: nowrap; /* Mencegah item turun ke baris baru */
  gap: 20px; /* Jarak antar ikon */

  white-space: nowrap; /* Pastikan tidak ada wrap */
  padding: 10px; /* Tambahkan padding jika diperlukan */
}

.ws {
  width: 160px;
  height: 150px;
}

#rpt {
  color: white;
  display: block;
  font-weight: bold;
  transform: translateY(-3px);
}

.wrapper-feature {
  width: 100%;
  height: auto;
  gap: 20px;
  display: flex;
  flex-direction: row;
}
/* Force landscape orientation */
@media (orientation: portrait) {
  .must-rotate {
    content: "Please rotate your device to landscape mode";
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    font-size: 24px;
    z-index: 1000;
  }
}
.wrapper-content {
  width: 100%;
  justify-items: center;
  align-items: center;
  /* gap: 20px; */
  height: 100dvh;

  margin-top: 6%;
  overflow-y: hidden;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  overflow-x: scroll !important;
}
.wrapper-billboard {
  width: calc(71% + 50px);
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  height: 96dvh;
}

/* Force landscape orientation */
@media (orientation: landscape) {
  .must-rotate {
    display: none;
  }
}

body {
  font-family: "Inter", sans-serif;
}

.game-dashboard {
  width: calc(100% + 5px);
  height: calc(100dvh + 5px);
  transform: translate(-5px, -5px);
  overflow-y: hidden;
  overflow-x: hidden;
  background-image: url(../../assets/img/game.jpg);
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 20px;
  display: flex;
  align-items: center;
}

.dashboard-claimBoard {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/game.jpg);
  background-size: cover;
  background-position: center;
  position: relative;

  overflow-y: auto !important;
  display: flex; /* Jika ingin menggunakan align-items */
  align-items: center; /* Hanya bekerja jika display adalah flex atau grid */
}

.wrapper-claimboard {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: auto !important;
  height: auto -100px;
}

.countdown {
  position: absolute;
  bottom: 17px;
  font-family: "Inter", sans-serif;
  left: 42%;
  z-index: 100;
  background: -webkit-linear-gradient(#898989, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
}

.task {
  background-image: url(../../assets/img/task1.png);
  width: 46%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 300px;
  padding: 80px 35px 205px 35px;
}

.back {
  left: 12px;
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
  border: none !important;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.claimBoard {
  background-image: url(../../assets/img/task.png);
  width: 500px;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 650px;
  position: relative;
  padding: 80px 80px;
  margin-bottom: 50px;
  padding-bottom: 120px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: start;
  gap: 20px;
}

.judul {
  position: absolute;
  bottom: 14px;
  font-family: "Inter", sans-serif;
  left: 45%;
  z-index: 100;
  background: -webkit-linear-gradient(#898989, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 10px;
}
.wrapper-list-task {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 140px;
  overflow-y: scroll;
}

.list {
  background-color: #2088ea;
  width: 100%;
  padding: 10px;
  color: white;
}

.box2 {
  width: 100%;
  max-width: 400px;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.box2 img {
  width: 100%;
  height: 120px;
  /* object-fit: contain; */
}

.energi {
  width: calc(200px);
  height: calc(50px);
  background-image: url(../../assets/img/energi.png);
  background-size: 100%;
  position: fixed;
  top: 10px;
  right: 30px;
  padding-left: 58px;
  padding-right: 8px;
  padding-top: 16px;
}

.RPT {
  width: calc(200px);
  height: calc(50px);
  background-image: url(../../assets/img/rpt.png);
  background-size: 100%;
  position: fixed;
  top: 10px;
  right: 260px;
  padding-left: 58px;
  padding-right: 8px;
  padding-top: 16px;
}

.progress-energi {
  width: 100%;
  height: 19px;
  background-color: lightskyblue;
  padding-left: 10px;
  font-weight: bold;
  color: #000000;
  display: flex;
  align-items: center;
}

/* .progress-energi:first-child {
  transform: translateY(-3px);
} */

.progress-rpt {
  width: 100%;
  height: 19px;
  background-color: green;
  padding-left: 10px;
}

.box-game {
  width: auto;
  height: 100dvh;
}

.menu-icon2 {
  /* width: 30px;
  height: 30px; */
  background-image: url(../../assets/img/icon.png) !important;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

</style>