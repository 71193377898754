<template>
  <div class="regis bg-white p-3 pt-5">
    <h3 class="fw-bold">Register First</h3>
    <br><br>
    <form @submit.prevent="registerUser">
      <div class="mb-3">
        <label for="nama" class="form-label">Nama Anda</label>
        <input type="text" class="form-control" id="nama" v-model="form.name">
      </div>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Email Anda</label>
        <div class="d-flex gap-2">
          <input type="email" class="form-control w-75" id="exampleInputEmail1" v-model="form.email">
          <button type="button" class="btn btn-outline-primary w-25" @click="otpSender()">OTP</button>
        </div>
      </div>
      <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input type="text" class="form-control" id="username" v-model="form.username">
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <div class="input-group">
          <input id="password-field" :type="passwordFieldType" class="form-control" v-model="form.password">
          <span class="input-group-text text-secondary toggle-password" @click="togglePasswordVisibility">
            <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </span>
        </div>
      </div>
      <div class="mb-3">
        <label for="otp" class="form-label">OTP</label>
        <input type="tel" minlength="6" maxlength="6" class="form-control" id="otp" v-model="form.otp" required>
      </div>
      <button type="submit" class="btn w-100 btn-lg btn-primary w-100">Submit</button>
      <router-link to="/" class="btn w-100 btn-lg btn-outline-secondary mt-2 w-100">Kembali</router-link>
    </form>
    <p class="mt-3 mb-5">Sudah Punya akun? <router-link to="/login">Login</router-link></p>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

export default {
  name: 'RegisView',
  data() {
    return {
      form: {
        name: '',
        email: '',
        username: '',
        password: '',
      },
      passwordFieldType: 'password',
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async registerUser() {
      try {
        let cek_otp = Cookies.get('otp');
        let cek_email = Cookies.get('email');
        if(this.form.otp == cek_otp && this.form.email == cek_email){
          await axios.post('https://api.tokoku.org/public/api/register', this.form);
          
          // Menampilkan SweetAlert2 saat registrasi berhasil
          Swal.fire({
            title: 'Registration Successful!',
            text: 'Your account has been created.',
            icon: 'success',
            confirmButtonText: 'Go to Login',
          }).then(() => {
            // Arahkan pengguna ke halaman login
            this.$router.push('/login');
          });
        }else {
          Swal.fire({
            title: 'OTP Salah',
            icon: 'error',
            confirmButtonText: 'Okay',
          });

        }
      } catch (error) {
        if (error.response) {
          // Menampilkan error dengan SweetAlert2
          Swal.fire({
            title: 'Error!',
            text: JSON.stringify(error.response.data),
            icon: 'error',
            confirmButtonText: 'Try Again',
          });
        } else {
          Swal.fire({
            title: 'Oops...',
            text: 'Something went wrong. Please try again.',
            icon: 'error',
            confirmButtonText: 'Okay',
          });
        }
      }
    },
    otpSender(){
      if(this.form.email === ''){
          Swal.fire({
            title: 'Email Kosong',
            text: 'Isi Email Terlebih dulu.',
            icon: 'error',
            confirmButtonText: 'Okay',
          });
      }else {
        axios.post('https://api.tokoku.org/public/api/send-otp', {
          email: this.form.email,
        })
        .then(res => {
          Cookies.set('email', res.data.otp_email, { expires: 7 });
          Cookies.set('otp', res.data.otp, { expires: 7 });
          // Menampilkan SweetAlert2 saat registrasi berhasil
          Swal.fire({
            title: 'Cek Email Anda!',
            icon: 'success'
          })
        })
      }

    }
  },
};
</script>

<style>
  .regis {
    width: 100%;
    min-height: 100dvh;
  }
</style>
